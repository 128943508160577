<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    carriers: {type: Object, required: true,},
    modelValue: {
        type: Array,
        required: true,
    },
    selectAllButton: {type: Boolean, default: true,},
});
const emit = defineEmits(['update:modelValue']);
const nextButtonDisabled = ref(false);

const filter = ref('');
const filteredCarriers = computed(() => {
    if (!filter.value) {
        return props.carriers;
    }
    return props.carriers?.filter(carrier => carrier.carrier_name.toLowerCase().includes(filter.value.toLowerCase()));
});

onMounted(() => {

});

const submitForm = function() {
    emit('completed');
}

const toggleSelection = function(carrier_id) {
    const newValue = [...props.modelValue];
    if (isSelected(carrier_id)) {
        let index = newValue.indexOf(carrier_id);
        newValue.splice(index, 1);
    } else {
        newValue.push(carrier_id);
    }
    emit('update:modelValue', newValue);
}

const isSelected = function(carrier_id) {
    return props.modelValue.includes(carrier_id);
}

const allSelected = computed(() => {
    return props.carriers?.length && props.modelValue?.length === props.carriers?.length;
});

const selectAll = function() {
    if (allSelected.value) {
        emit('update:modelValue', []);
        return;
    }

    const newValue = props.carriers.map(i => i.id);
    emit('update:modelValue', newValue);
}

const logoUrl = function(carrier) {
    return `https://apps.topbrokercrm.com/${carrier.carrier_logo}`;
    return `/${carrier.carrier_logo}`;
}
</script>

<template>
  <div>
    <div class="flex justify-between">
      <div v-if="selectAllButton">
        <button class="btn-primary" @click.prevent="selectAll">{{ allSelected ? 'Deselect all' : 'Select all' }}</button>
      </div>
      <div>
        <input type="text" placeholder="Filter carriers" v-model="filter" class="bg-transparent border-0 border-b" />
      </div>
    </div>
    <div class="mt-8">
      <div v-if="filteredCarriers">
        <form method="POST" @submit.prevent="submitForm">
          <div class="grid grid-cols-12 gap-2">
            <div
              class="col-span-3 relative rounded-lg bg-white text-center shadow cursor-pointer"
              v-for="(carrier, index) in filteredCarriers"
              @click="toggleSelection(carrier.id)"
            >
              <div v-show="isSelected(carrier.id)" class="absolute inset-0 bg-blue-900 opacity-30 z-40"></div>
              <div class="relative -mr-px inline-flex flex-col flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                <img v-if="carrier.carrier_logo" class="mx-auto h-40 w-40 flex-shrink-0 rounded-md object-fit object-contain"
                     :src="logoUrl(carrier)" alt=""/>
                <span class="mt-2 px-8 text-sm">{{ carrier.carrier }}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <span>No carriers found</span>
      </div>
    </div>
  </div>
</template>