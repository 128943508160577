import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/index';
import { ElNotification } from 'element-plus';
import phoneFormatterMixin from './Components/mixins/phoneFormatterMixin';
window.$notify = ElNotification;
import * as Sentry from "@sentry/vue";
import AppLeadCreateForm from "./Components/AppLeadCreateForm.vue";
import AppLeadView from "./Components/AppLeadView.vue"
import AppLeadSourceForm from "./Components/AppLeadSourceForm.vue";
import AppLeadSourceView from "./Components/AppLeadSourceView.vue";
import AppLeadStatusAndAssignment from "./Components/AppLeadStatusAndAssignment.vue";
import LeadSearchTable from "./Components/LeadSearchTable.vue";
import LeadSearchTableItem from "./Components/LeadSearchTableItem.vue";
import AppAgencyView from "./Components/AppAgencyView.vue";
import AppAgentCreate from "./Components/AppAgentCreate.vue";
import AppAgentsTable from "./Components/AppAgentsTable.vue";
import AppAgentView from "./Components/AppAgentView.vue";
import AppActionScheduleCreateForm from "./Components/AppActionScheduleCreateForm.vue";
import AppActionScheduleViewForm from "./Components/AppActionScheduleViewForm.vue";
import AppVoicemailScriptForm from "./Components/AppVoicemailScriptForm.vue";
import Mask from "maska";
import Swal from 'sweetalert2';
window.Swal = Swal;

import mitt from "mitt";
window.mitt = mitt();

// Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
// Froala third party plugins
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
// Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import VueFroala from 'vue-froala-wysiwyg';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
window.visibleGlobalNotifies = [];

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup ({ el, app, props, plugin }) {
        const vm = createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Mask)
            .use(VueFroala)
            .component('app-lead-create-form', AppLeadCreateForm)
            .component('app-lead-view', AppLeadView)
            .component('app-lead-source-form', AppLeadSourceForm)
            .component('app-lead-source-view', AppLeadSourceView)
            .component('app-lead-status-and-assignment', AppLeadStatusAndAssignment)
            .component('lead-search-table', LeadSearchTable)
            .component('lead-search-table-item', LeadSearchTableItem)
            .component('app-agency-view', AppAgencyView)
            .component('app-agent-create', AppAgentCreate)
            .component('app-agents-table', AppAgentsTable)
            .component('app-agent-view', AppAgentView)
            .component('app-action-schedule-create-form', AppActionScheduleCreateForm)
            .component('app-action-schedule-view-form', AppActionScheduleViewForm)
            .component('app-voicemail-script-form', AppVoicemailScriptForm)
            .mixin(phoneFormatterMixin)
            .mount(el);

        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                vm,
                dsn: "https://40f28c412d0f4831b01a98180e07b463@o252856.ingest.sentry.io/4505171365986304",
                integrations: [
                    new Sentry.Replay({
                        maskAllInputs: false,
                        maskAllText: false
                    }),
                ],
                replaysOnErrorSampleRate: 1.0,
            });
        }

        return vm;

    },
});

InertiaProgress.init({ color: '#4B5563' });
