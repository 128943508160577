<template>
  <div v-show="editMode">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Agency Name -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('name')}">
        <label for="name" class="form-label  text-gray-600">Agency Name</label>
        <input v-model="name" id="name" type="text" class="input-field  w-full" name="name">
        <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
      </div>
      <!-- END: Agency Name -->

      <!-- BEGIN: Designated Support Person -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('support_person_id')}">
        <label for="support_person_id" class="form-label  text-gray-600">Designated Support Person</label>
        <select v-model="support_person_id" data-placeholder="Select designated support person" class="input-field w-full" id="support_person_id" name="support_person_id">
          <option v-for="(person) in available_support_persons" :key="person.id" :value="person.id">{{ person.first_name }} {{ person.last_name }}</option>
        </select>
        <div v-if="isInvalid('support_person_id')" class="error-message mt-1">{{ errorMessage('support_person_id') }}</div>
      </div>
      <!-- END: Designated Support Person -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Default Action Schedule -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('default_action_schedule_id')}">
        <label for="default_action_schedule_id" class="form-label  text-gray-600">Default Action Schedule</label>
        <select v-model="default_action_schedule_id" class="input-field  w-full" id="default_action_schedule_id" name="default_action_schedule_id">
          <option v-for="(actionSchedule) in action_schedules" :key="actionSchedule.id" :value="actionSchedule.id">{{ actionSchedule.name }}</option>
        </select>
        <div v-if="isInvalid('default_action_schedule_id')" class="error-message mt-1">{{ errorMessage('default_action_schedule_id') }}</div>
      </div>
      <!-- END: Default Action Map -->


      <!-- BEGIN: Grace Pull Enabled -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('grace_pulls_enabled')}">
        <label for="grace_pulls_enabled" class="form-label  text-gray-600">Grace Feature (Master Control)</label>
        <select v-model="grace_pulls_enabled" class="input-field  w-full" id="grace_pulls_enabled" name="grace_pulls_enabled">
          <option :value="1">Active</option>
          <option :value="0">Inactive</option>
        </select>
        <div v-if="isInvalid('grace_pulls_enabled')" class="error-message mt-1">{{ errorMessage('grace_pulls_enabled') }}</div>
      </div>
      <!-- END: Grace Pull Enabled -->


    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-4">
      <!-- BEGIN: Missed Appointment - Change Status after.. -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('missed_appt_hours')}">
        <label for="missed_appt_hours" class="form-label text-gray-600">Missed Appointment - Change Status after..</label>
        <select v-model="missed_appt_hours" class="input-field  w-full" id="missed_appt_hours" name="missed_appt_hours">
          <option value="0">— Do Not Automatically Change —</option>
          <option v-for="(hour) in missed_appt_hours_options" :key="hour.value" :value="hour.value">{{ hour.label }}</option>
        </select>
        <div v-if="isInvalid('missed_appt_hours')" class="error-message mt-1">{{ errorMessage('missed_appt_hours') }}</div>
      </div>
      <!-- END: Missed Appointment - Change Status after.. -->

      <!-- BEGIN: Missed Appointment - Move To Bucket -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('missed_appt_bucket_id')}">
        <label for="missed_appt_bucket_id" class="form-label  text-gray-600">Missed Appointment - Move To Bucket</label>
        <select v-model="missed_appt_bucket_id" class="input-field  w-full" id="missed_appt_bucket_id" name="missed_appt_bucket_id">
          <option value="0">— Do Not Move —</option>
          <option v-for="(bucket) in buckets" :key="bucket.id" :value="bucket.id">{{ bucket.name }}</option>
        </select>
        <div v-if="isInvalid('missed_appt_bucket_id')" class="error-message mt-1">{{ errorMessage('missed_appt_bucket_id') }}</div>
      </div>
      <!-- END: Missed Appointment - Move To Bucket -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-4 border-b border-slate-100 pb-6">
      <!-- BEGIN: Lead History -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('only_agent_history')}">
        <label for="only_agent_history" class="form-label  text-gray-600">Lead History</label>
        <select v-model="only_agent_history" class="input-field  w-full" id="only_agent_history" name="only_agent_history">
          <option value="0">Show All History to the Owning Agent</option>
          <option value="1">Only Show the agent their history for the lead</option>
        </select>
        <div v-if="isInvalid('only_agent_history')" class="error-message mt-1">{{ errorMessage('only_agent_history') }}</div>
      </div>
      <!-- END: Lead History -->

      <!-- BEGIN: Require Notes On Dead Status -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('require_notes_on_dead')}">
        <label for="require_notes_on_dead" class="form-label  text-gray-600">Require Notes On Dead Status</label>
        <select v-model="require_notes_on_dead" class="input-field  w-full" id="require_notes_on_dead" name="require_notes_on_dead">
          <option value="1">Yes, require the agent to enter notes</option>
          <option value="0">No</option>
        </select>
        <div v-if="isInvalid('require_notes_on_dead')" class="error-message mt-1">{{ errorMessage('require_notes_on_dead') }}</div>
      </div>
      <!-- END: Require Notes On Dead Status -->

      <!-- BEGIN: Require Two-Factor-Authentication For Lead Files -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('require_2fa_for_files')}">
        <label for="require_2fa_for_files" class="form-label  text-gray-600">Require Two-Factor-Authentication For Lead Files</label>
        <select v-model="require_2fa_for_files" class="input-field  w-full" id="require_2fa_for_files" name="require_2fa_for_files">
          <option :value="1">Yes, require the agent to use 2FA for Lead Files</option>
          <option :value="0">No</option>
        </select>
        <div v-if="isInvalid('require_2fa_for_files')" class="error-message mt-1">{{ errorMessage('require_2fa_for_files') }}</div>
      </div>
      <!-- END: Require Two-Factor-Authentication For Lead Files -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-4 border-b border-slate-100 pb-6">
      <!-- BEGIN: Grace Pulls On Weekends -->
      <div class="col-span-12 lg:col-span-12 input-form" :class="{'has-error': isInvalid('grace_pulls_on_weekends')}">
        <label for="grace_pulls_on_weekends" class="form-label  text-gray-600">Grace Feature - Weekends</label>
        <select v-model="grace_pulls_on_weekends" class="input-field  w-full" id="grace_pulls_on_weekends" name="grace_pulls_on_weekends">
          <option :value="1">Pull leads that are past due on the weekends</option>
          <option :value="0">Do NOT pull leads on weekends, resume Monday mornings (12pm EST).</option>
        </select>
        <div v-if="isInvalid('grace_pulls_on_weekends')" class="error-message mt-1">{{ errorMessage('grace_pulls_on_weekends') }}</div>
      </div>
      <!-- END: Grace Pulls On Weekends -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Quotesheet Subject -->
      <div class="col-span-12 lg:col-span-12 input-form" :class="{'has-error': isInvalid('quotesheet_subject')}">
        <label for="quotesheet_subject" class="form-label  text-gray-600">Quotesheet - Subject</label>
        <input v-model="quotesheet_subject" id="quotesheet_subject" type="text" class="input-field  w-full" name="quotesheet_subject">
        <div v-if="isInvalid('quotesheet_subject')" class="error-message mt-1">{{ errorMessage('quotesheet_subject') }}</div>
      </div>
      <!-- END: Quotesheet Subject -->

      <!-- BEGIN: Quotesheet Body -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('quotesheet_body')}">
        <label for="quotesheet_body" class="form-label  text-gray-600">Quotesheet - Body</label>
        <AppFroala v-model:value="quotesheet_body"></AppFroala>
        <div v-if="isInvalid('quotesheet_body')" class="error-message mt-1">{{ errorMessage('quotesheet_body') }}</div>
      </div>
      <!-- END: Quotesheet Body -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Agency Currency Setting -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('currency')}">
        <label for="currency" class="form-label  text-gray-600">Agency Currency Setting</label>
        <select v-model="currency" class="input-field  w-full" id="currency" name="currency">
          <option value="D">Dollars</option>
          <option value="C">Credits</option>
        </select>
        <div v-if="isInvalid('currency')" class="error-message mt-1">{{ errorMessage('currency') }}</div>
      </div>
      <!-- END: Agency Currency Setting -->

      <!-- BEGIN: Allow agents to create questionnaires? -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('allow_agent_questionnaire')}">
        <label for="allow_agent_questionnaire" class="form-label  text-gray-600">Allow agents to create questionnaires?</label>
        <select v-model="allow_agent_questionnaire" class="input-field  w-full" id="allow_agent_questionnaire" name="allow_agent_questionnaire">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        <div v-if="isInvalid('allow_agent_questionnaire')" class="error-message mt-1">{{ errorMessage('allow_agent_questionnaire') }}</div>
      </div>
      <!-- END: Allow agents to create questionnaires? -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Quotesheet Colors #1 -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('color_1')}">
        <label for="color_1" class="form-label  text-gray-600 block">Quotesheet Colors #1</label>
        <input id="color_1" type="text" class="color-field input-field  w-full" v-model="color_1" data-coloris>
        <div v-if="isInvalid('color_1')" class="error-message mt-1">{{ errorMessage('color_1') }}</div>
      </div>
      <!-- END: Quotesheet Colors #1 -->

      <!-- BEGIN: Quotesheet Colors #2 -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('color_2')}">
        <label for="color_2" class="form-label  text-gray-600 block">Quotesheet Colors #2</label>
        <input id="color_2" type="text" class="color-field input-field  w-full" v-model="color_2" data-coloris>
        <div v-if="isInvalid('color_2')" class="error-message mt-1">{{ errorMessage('color_2') }}</div>
      </div>
      <!-- END: Quotesheet Colors #2 -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Quotesheet Colors #3 -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('color_3')}">
        <label for="color_3" class="form-label  text-gray-600 block">Quotesheet Colors #3</label>
        <input id="color_3" type="text" class="color-field input-field  w-full" v-model="color_3" data-coloris>
        <div v-if="isInvalid('color_3')" class="error-message mt-1">{{ errorMessage('color_3') }}</div>
      </div>
      <!-- END: Quotesheet Colors #3 -->

      <!-- BEGIN: Quotesheet Colors #4 -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('color_4')}">
        <label for="color_4" class="form-label  text-gray-600 block">Quotesheet Colors #4</label>
        <input id="color_4" type="text" class="color-field input-field  w-full" v-model="color_4" data-coloris>
        <div v-if="isInvalid('color_4')" class="error-message mt-1">{{ errorMessage('color_4') }}</div>
      </div>
      <!-- END: Quotesheet Colors #4 -->
    </div>

    <!-- Divider -->
    <div class="border-b border-slate-100"></div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Carrier / Product Delegation -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('delegate_carrier_product_control')}">
        <label for="delegate_carrier_product_control" class="form-label  text-gray-600">Delegate Carrier and Product Management to TopBroker</label>
        <select v-model="delegate_carrier_product_control" class="input-field  w-full" id="delegate_carrier_product_control" name="delegate_carrier_product_control">
          <option :value="1">Yes</option>
          <option :value="0">No</option>
        </select>
        <div v-if="isInvalid('delegate_carrier_product_control')" class="error-message mt-1">{{ errorMessage('currency') }}</div>
      </div>
      <!-- END: Carrier / Product Delegation -->
    </div>

    <!-- Divider -->
    <div class="border-b border-slate-100"></div>

    <!-- UI Customizations Wrapper -->
    <div v-if="canUseUiCustomizations">
      <!-- BEGIN: Use UI Customizations? -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('use_ui_customizations')}">
        <label for="use_ui_customizations" class="form-label  text-gray-600">Use UI Customizations?</label>
        <select v-model="use_ui_customizations" class="input-field  w-full" id="use_ui_customizations" name="use_ui_customizations">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        <div v-if="isInvalid('use_ui_customizations')" class="error-message mt-1">{{ errorMessage('use_ui_customizations') }}</div>
      </div>
      <!-- END: Use UI Customizations? -->

      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
        <!-- BEGIN: Ui Color #1 -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('ui_color_1')}">
          <label for="ui_color_1" class="form-label  text-gray-600 block">UI Color #1</label>
          <input id="ui_color_1" type="text" class="color-field input-field  w-full" v-model="ui_color_1" data-coloris>
          <div v-if="isInvalid('ui_color_1')" class="error-message mt-1">{{ errorMessage('ui_color_1') }}</div>
        </div>
        <!-- END: Ui Color #1 -->

        <!-- BEGIN: Ui Color #2 -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('ui_color_2')}">
          <label for="ui_color_2" class="form-label text-gray-600 block">UI Color #2</label>
          <input id="ui_color_2" type="text" class="color-field input-field  w-full" v-model="ui_color_2" data-coloris>
          <div v-if="isInvalid('ui_color_2')" class="error-message mt-1">{{ errorMessage('ui_color_2') }}</div>
        </div>
        <!-- BEGIN: Ui Color #2 -->
      </div>

      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
        <!-- BEGIN: Ui Login Background Color -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('ui_login_background_color')}">
          <label for="ui_login_background_color" class="form-label  text-gray-600 block">UI Login Background Color</label>
          <input id="ui_login_background_color" type="text" class="color-field input-field  w-full" v-model="ui_login_background_color" data-coloris>
          <div v-if="isInvalid('ui_login_background_color')" class="error-message mt-1">{{ errorMessage('ui_login_background_color') }}</div>
        </div>
        <!-- END: Ui Login Background Color -->

        <!-- BEGIN: Ui Topbar Background Color -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('ui_topbar_background_color')}">
          <label for="ui_topbar_background_color" class="form-label text-gray-600 block">UI Topbar Background Color</label>
          <input id="ui_topbar_background_color" type="text" class="color-field input-field  w-full" v-model="ui_topbar_background_color" data-coloris>
          <div v-if="isInvalid('ui_topbar_background_color')" class="error-message mt-1">{{ errorMessage('ui_topbar_background_color') }}</div>
        </div>
        <!-- BEGIN: Ui Topbar Background Color -->

        <!-- BEGIN: Ui Topbar Foreground Color -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('ui_topbar_foreground_color')}">
          <label for="ui_topbar_foreground_color" class="form-label text-gray-600 block">UI Topbar Text Color</label>
          <input id="ui_topbar_foreground_color" type="text" class="color-field input-field  w-full" v-model="ui_topbar_foreground_color" data-coloris>
          <div v-if="isInvalid('ui_topbar_foreground_color')" class="error-message mt-1">{{ errorMessage('ui_topbar_foreground_color') }}</div>
        </div>
        <!-- BEGIN: Ui Topbar Foreground Color -->
      </div>

      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
        <!-- BEGIN: Logo #1 -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('logo')}">
          <img v-if="formData.logo_path" :src="formData.logo_path" class="mb-2 h-12 max-w-[200px]  object-scale-down border p-2 rounded" alt="logo without text">
          <label for="dz" class="form-label text-slate-600">
            Logo
            <span v-if="formData.logo_path">
            (<a :href="formData.logo_path" target="_blank">Click to see current logo</a> / leave blank to keep)
          </span>
          </label>
          <!-- BEGIN: Dropzone -->
          <div class="dropzone rounded-lg border-slate-200" :class="{'has-error': isInvalid('logo')}" id="agency-logo-file-dropzone">
            <div class="fallback">
              <input name="file" type="file"/>
            </div>
            <div class="dz-message">
              <div class="text-lg font-medium">Drop logo here or click to upload.</div>
              <div class="text-gray-600">
                This is the logo that will be used on the sidebar when its not minimized, and on the log-in page. For best looking results it should be a rectangle image, 4:1 aspect ratio is recommended.
              </div>
            </div>
          </div>
          <!-- END: Dropzone -->
          <div v-if="isInvalid('logo')" class="error-message mt-1">{{ errorMessage('logo') }}</div>
        </div>
        <!-- END: Logo #1 -->

        <!-- BEGIN: Logo Without Text -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('logo_without_text')}">
          <img v-if="formData.logo_without_text_path" :src="formData.logo_without_text_path" class="mb-2 w-12 h-12 object-scale-down border p-2 rounded" alt="logo without text">
          <label for="dz" class="form-label text-slate-600">
            Logo without text
            <span v-if="formData.logo_without_text_path">
            (<a :href="formData.logo_without_text_path" target="_blank">Click to see current logo</a> / leave blank to keep)
          </span>
          </label>
          <!-- BEGIN: Dropzone -->
          <div class="dropzone rounded-lg border-slate-200" :class="{'has-error': isInvalid('logo_without_text')}" id="agency-logo-without-text-dropzone">
            <div class="fallback">
              <input name="file" type="file"/>
            </div>
            <div class="dz-message">
              <div class="text-lg font-medium">Drop logo without text here or click to upload.</div>
              <div class="text-gray-600">
                This is the logo that will be used when the sidebar is minimized. For best looking results it should be a square image.
              </div>
            </div>
          </div>
          <!-- END: Dropzone -->
          <div v-if="isInvalid('logo_without_text')" class="error-message mt-1">{{ errorMessage('logo_without_text') }}</div>
        </div>
        <!-- END: Logo Without Text -->
      </div>
    </div>
    <!-- END: UI Customizations Wrapper -->

    <!-- Divider -->
    <div class="border-b border-slate-100"></div>

    <div v-show="notify" class="rounded-lg bg-green-50 p-4 mt-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-green-800">{{ notificationMessage }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button @click="notify = false;" type="button" class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <button @click.prevent="submit" type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Update</span>
      </button>
    </div>
  </div>

  <!-- Read Only Mode -->
  <agency-info-read-only v-if="!editMode" :agency_info="agency_info" @show-edit-mode="handleShowEditMode"></agency-info-read-only>
</template>

<script>
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import helpers from "../../helper";
import AgencyInfoReadOnly from "./AgencyInfoReadOnly.vue";
import _ from "lodash";
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {CheckCircleIcon, XIcon} from "@heroicons/vue/solid";
import AppFroala from "@/Components/AppFroala.vue";
import Dropzone from "dropzone";


export default {
  name: "AgencyInfo",

  mixins: [serverValidationErrorsMixin],

  components: {
    AppFroala,
    AgencyInfoReadOnly,
    LoadingSpinner,
    CheckCircleIcon,
    XIcon
  },

  props: {
    agency_info: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    available_support_persons: {
      type: Array,
      default: () => [],
    },
    action_schedules: {
      type: Array,
      default: () => [],
    },
    missed_appt_hours_options: {
      type: Array,
      default: () => [],
    },
    buckets: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:agency_info', 'show-edit-mode'],

  data() {
    return {
      loading: false,
      notify: false,
      notificationMessage: 'Agency updated',
      formData: _.cloneDeep(this.agency_info),
      logoDropzone: null,
      logoWithoutTextDropzone: null,
      dropzoneOptions: {
        maxFiles: 1,
        maxFilesize: 2, // mb
        parallelUploads: 1, // 1 was set
        paramName: "import-file",
        addRemoveLinks: true,
        uploadMultiple: false,
        url: '/', // Just a placeholder, needed because DZ asks for it, but we process the upload manually.
        autoProcessQueue: false,
        createImageThumbnails: false,
        accept: function (file, done) {
          const allowedMimeTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
          ];
          if (!allowedMimeTypes.includes(file.type)) {
            done("Error! Files of this type are not accepted");
          } else {
            done();
          }
        }
      }
    }
  },

  mounted() {
    this.initColoris();
    this.initDropzones();
  },

  unmounted() {
    this.removeAllFilesFromDropzones();
  },

  computed: {
    name: {
      get() {
        return this.formData.name;
      },
      set(value) {
        this.formData.name = value;
      }
    },
    support_person_id: {
      get() {
        return this.formData.support_person_id;
      },
      set(value) {
        this.formData.support_person_id = value;
      }
    },
    default_action_schedule_id: {
      get() {
        return this.formData.default_action_schedule_id;
      },
      set(value) {
        this.formData.default_action_schedule_id = value;
      }
    },
    grace_pulls_enabled: {
      get() {
          return this.formData.grace_pulls_enabled;
      },
      set(value) {
          this.formData.grace_pulls_enabled = value;
      }
    },
    grace_pulls_on_weekends: {
      get() {
          return this.formData.grace_pulls_on_weekends;
      },
      set(value) {
          this.formData.grace_pulls_on_weekends = value;
      }
    },
    missed_appt_hours: {
      get() {
        return this.formData.missed_appt_hours ?? 0;
      },
      set(value) {
        this.formData.missed_appt_hours = value;
      }
    },
    missed_appt_bucket_id: {
      get() {
        return this.formData.missed_appt_bucket_id ?? 0;
      },
      set(value) {
        this.formData.missed_appt_bucket_id = value;
      }
    },
    only_agent_history: {
      get() {
        return this.formData.only_agent_history;
      },
      set(value) {
        this.formData.only_agent_history = value;
      }
    },
    require_notes_on_dead: {
      get() {
        return this.formData.require_notes_on_dead;
      },
      set(value) {
        this.formData.require_notes_on_dead = value;
      }
    },
    require_2fa_for_files: {
      get() {
        return this.formData.require_2fa_for_files;
      },
      set(value) {
        this.formData.require_2fa_for_files = value;
      }
    },
    birthday_email_subject: {
      get() {
        return this.formData.birthday_email_subject;
      },
      set(value) {
        this.formData.birthday_email_subject = value;
      }
    },
    birthday_email_body: {
      get() {
        return this.formData.birthday_email_body;
      },
      set(value) {
        this.formData.birthday_email_body = value;
      }
    },
    quotesheet_subject: {
      get() {
        return this.formData.quotesheet_subject;
      },
      set(value) {
        this.formData.quotesheet_subject = value;
      }
    },
    quotesheet_body: {
      get() {
        return this.formData.quotesheet_body;
      },
      set(value) {
        this.formData.quotesheet_body = value;
      }
    },
    appointment_subject: {
      get() {
        return this.formData.appointment_subject;
      },
      set(value) {
        this.formData.appointment_subject = value;
      }
    },
    appointment_body: {
      get() {
        return this.formData.appointment_body;
      },
      set(value) {
        this.formData.appointment_body = value;
      }
    },
    notify_firstsale: {
      get() {
        return this.formData.notify_firstsale;
      },
      set(value) {
        this.formData.notify_firstsale = value;
      }
    },
    notify_badphone: {
      get() {
        return this.formData.notify_badphone;
      },
      set(value) {
        this.formData.notify_badphone = value;
      }
    },
    notify_deadlead: {
      get() {
        return this.formData.notify_deadlead;
      },
      set(value) {
        this.formData.notify_deadlead = value;
      }
    },
    notify_carrierreq: {
      get() {
        return this.formData.notify_carrierreq;
      },
      set(value) {
        this.formData.notify_carrierreq = value;
      }
    },
    notify_splitsale: {
      get() {
        return this.formData.notify_splitsale;
      },
      set(value) {
        this.formData.notify_splitsale = value;
      }
    },
    currency: {
      get() {
        return this.formData.currency;
      },
      set(value) {
        this.formData.currency = value;
      }
    },
    delegate_carrier_product_control: {
      get() {
        return this.formData.delegate_carrier_product_control;
      },
      set(value) {
        this.formData.delegate_carrier_product_control = value;
      }
    },
    allow_agent_questionnaire: {
      get() {
        return this.formData.allow_agent_questionnaire;
      },
      set(value) {
        this.formData.allow_agent_questionnaire = value;
      }
    },
    color_1: {
      get() {
        return this.formData.color_1;
      },
      set(value) {
        this.formData.color_1 = value;
      }
    },
    color_2: {
      get() {
        return this.formData.color_2;
      },
      set(value) {
        this.formData.color_2 = value;
      }
    },
    color_3: {
      get() {
        return this.formData.color_3;
      },
      set(value) {
        this.formData.color_3 = value;
      }
    },
    color_4: {
      get() {
        return this.formData.color_4;
      },
      set(value) {
        this.formData.color_4 = value;
      }
    },
    use_ui_customizations: {
      get() {
        return this.formData.use_ui_customizations;
      },
      set(value) {
        this.formData.use_ui_customizations = value;
      }
    },
    ui_color_1: {
      get() {
        return this.formData.ui_color_1;
      },
      set(value) {
        this.formData.ui_color_1 = value;
      }
    },
    ui_color_2: {
      get() {
        return this.formData.ui_color_2;
      },
      set(value) {
        this.formData.ui_color_2 = value;
      }
    },
    ui_login_background_color: {
      get() {
        return this.formData.ui_login_background_color;
      },
      set(value) {
        this.formData.ui_login_background_color = value;
      }
    },
    ui_topbar_background_color: {
      get() {
        return this.formData.ui_topbar_background_color;
      },
      set(value) {
        this.formData.ui_topbar_background_color = value;
      }
    },
    ui_topbar_foreground_color: {
      get() {
        return this.formData.ui_topbar_foreground_color;
      },
      set(value) {
        this.formData.ui_topbar_foreground_color = value;
      }
    },
    logo: {
      get() {
        return this.formData.logo;
      },
      set(value) {
        this.formData.logo = value;
      },
    },
    logo_without_text: {
      get() {
        return this.formData.logo_without_text;
      },
      set(value) {
        this.formData.logo_without_text = value;
      }
    },
    canUseUiCustomizations: function() {
        return this.agency_info.domain;
    },
  },

  methods: {
    initColoris() {
      Coloris.init();
      Coloris({
        el: '.color-field',
        format: 'hex',
        alpha: false,
        clearButton: {
          show: true,
          label: 'Clear'
        },
        focusInput: false,
      });
    },
    submit(e) {
      if (this.loading) return;
      this.loading = true;

      let data = new FormData();
      for (let key in this.formData) {
        if (this.formData[key] !== null) {
          data.append(key, this.formData[key]);
        }
      }

      setTimeout(() => {
        axios
          .post(`/agencies/${this.agency_info.id}`, data)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
          })
          .catch((error) => {
            console.log(error, 'AgencyInfo - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.$emit('update:agency_info', response.data);

      if (response.data && response.data.message) {
        this.showNotification(response.data.message);
      } else {
        this.showNotification();
      }

      this.errors = {};
    },

    handleShowEditMode(payload) {
      this.$emit('show-edit-mode', payload);
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgencyInfo - handleErroredSubmission", error.response);
    },

    showNotification(message = null) {
      this.notify = true;
      if (message) {
        this.notificationMessage = message;
      }
    },

    initDropzones() {
      // The constructor of Dropzone accepts two arguments:
      // 1. The selector for the HTML element that you want to add Dropzone to
      // 2. An (optional) object with the configuration
      Dropzone.autoDiscover = false;
      this.initLogoDropzone();
      this.initLogoWithoutTextDropzone();
    },

    initLogoDropzone() {
      this.logoDropzone = new Dropzone('#agency-logo-file-dropzone', {
        maxFiles: 1,
        maxFilesize: 2, // mb
        parallelUploads: 1, // 1 was set
        paramName: "import-file",
        addRemoveLinks: true,
        uploadMultiple: false,
        url: '/', // Just a placeholder, needed because DZ asks for it, but we process the upload manually.
        autoProcessQueue: false,
        createImageThumbnails: false,
        accept: function (file, done) {
          const allowedMimeTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
          ];
          if (!allowedMimeTypes.includes(file.type)) {
            done("Error! Files of this type are not accepted");
          } else {
            done();
          }
        }
      });
      const vm = this;
      this.logoDropzone.on("error", (error) => {
        console.log(error);
      });
      this.logoDropzone.on("addedfile", function(file) {
        console.log(file);
        vm.formData.logo = file;
      });
      this.logoDropzone.on("removedfile", function(file) {
        vm.formData.logo = null;
      });
      this.logoDropzone.on("maxfilesexceeded", function (file) {
        vm.logoDropzone.removeAllFiles();
        vm.logoDropzone.addFile(file);
      });
    },

    initLogoWithoutTextDropzone() {
      this.logoWithoutTextDropzone = new Dropzone('#agency-logo-without-text-dropzone', {
        maxFiles: 1,
        maxFilesize: 2, // mb
        parallelUploads: 1, // 1 was set
        paramName: "import-file",
        addRemoveLinks: true,
        uploadMultiple: false,
        url: '/', // Just a placeholder, needed because DZ asks for it, but we process the upload manually.
        autoProcessQueue: false,
        createImageThumbnails: false,
        accept: function (file, done) {
          const allowedMimeTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
          ];
          if (!allowedMimeTypes.includes(file.type)) {
            done("Error! Files of this type are not accepted");
          } else {
            done();
          }
        }
      });
      let vm = this;
      this.logoWithoutTextDropzone.on("error", (error) => {
        console.log(error);
      });
      this.logoWithoutTextDropzone.on("addedfile", file => {
        vm.formData.logo_without_text = file;
      });
      this.logoWithoutTextDropzone.on("removedfile", file => {
        vm.formData.logo_without_text = null;
      });
      this.logoWithoutTextDropzone.on("maxfilesexceeded", function (file) {
        vm.logoWithoutTextDropzone.removeAllFiles();
        vm.logoWithoutTextDropzone.addFile(file);
      });
    },

    removeAllFilesFromDropzones() {
      this.logoDropzone?.removeAllFiles()
      this.logoWithoutTextDropzone?.removeAllFiles()
    }
  },
}
</script>

<style>
.clr-field {
  width: 100%;
}

/*.ck .ck .ck-content {*/
/*  min-height: 180px !important;*/
/*  height: 180px !important;*/
/*}*/
</style>
