<template>
  <div>
    <div class="space-y-6">

      <div v-if="sharing" class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div class="bg-tb-blue p-3">
              <h2 class="font-bold text-sm text-white">Existing Sale Sharing</h2>
            </div>
            <table class="min-w-full divide-y divide-gray-300 border-t border-gray-300">
              <thead class="bg-slate-100">
              <tr>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">Shared with</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Options</th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="share in sharing" :key="share.id">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ share.destination_user?.full_name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{{ share.status }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div>
                  <el-popconfirm
                    width="220"
                    title="Are you sure?"
                    confirm-button-text="Yes"
                    cancel-button-text="No"
                    @confirm="toggleActive(share)">
                    <template #reference>
                      <button class="btn-secondary shadow-md" type="button">
                        {{ share.status === 'active' ? 'Make inactive' : 'Make active' }}
                      </button>
                    </template>
                  </el-popconfirm>
                  <button class="btn-secondary ml-4" @click="showCarriers(share)">Carriers</button>
                  <el-popconfirm
                    width="220"
                    title="Are you sure?"
                    confirm-button-text="Yes"
                    cancel-button-text="No"
                    @confirm="doDelete(share)">
                    <template #reference>
                      <button class="btn-danger shadow-md ml-4" type="button">Delete</button>
                    </template>
                  </el-popconfirm>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="canSeeForm" class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div class="bg-tb-blue p-3">
              <h2 class="font-bold text-sm text-white">
                <span v-if="isCreating">New</span>
                <span v-else-if="isEditing">Editing</span>
              </h2>
            </div>
            <div class="px-3 py-3.5 bg-white shadow sm:rounded-lg">
              <div class="space-y-6">
                <!-- Agency Selection -->
                <div>
                  <label for="agency" class="block text-sm font-medium text-gray-700">Select Agency</label>
                  <select
                    id="agency"
                    v-model="form.agency_id"
                    @change="agencyChanged"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-tb-blue focus:ring-tb-blue sm:text-sm"
                  >
                    <option value="">Select an agency</option>
                    <option v-for="agency in agencies" :key="agency.id" :value="agency.id">
                      {{ agency.name }}
                    </option>
                  </select>
                  <span v-if="form.errors.agency_id" class="text-red-500 text-xs">{{ form.errors.agency_id }}</span>
                </div>

                <!-- Agent Selection -->
                <div v-if="form.agency_id">
                  <span class="text-sm text-orange-500">
                    An agent will be created in the selected agency to mirror sales.
                  </span>
                </div>

                <!-- Start Date -->
                <div>
                  <label for="start_date" class="block text-sm font-medium text-gray-700 flex items-center">
                    <span>Start Date</span>
                    <el-tooltip placement="top">
                      <template #content>
                        <div class="text-sm max-w-[300px] text-wrap">
                          <p>If this is empty, all sales will be shared, past and future.</p>
                        </div>
                      </template>
                      <QuestionMarkCircleIcon class="p-1 h-8 w-8 cursor-pointer" @click.prevent="true"></QuestionMarkCircleIcon>
                    </el-tooltip>
                  </label>
                  <el-date-picker
                    v-model="form.start_date"
                    type="date"
                    placeholder="Pick a date"
                    format="M/D/YYYY"
                    value-format="YYYY-MM-DD"
                    :default-value="new Date()"
                    class="w-full"
                  />
                  <span v-if="form.errors.start_date" class="text-red-500 text-xs">{{ form.errors.start_date }}</span>
                </div>

                <!-- Carriers Selection -->
                <div>
                  <div class="flex items-center space-x-4 mb-4">
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        v-model="form.all_carriers"
                        class="rounded border-gray-300 text-tb-blue shadow-sm focus:border-tb-blue focus:ring focus:ring-tb-blue focus:ring-opacity-50"
                      />
                      <span class="ml-2 text-sm text-gray-700">All carriers</span>
                    </label>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        v-model="form.future_carriers"
                        class="rounded border-gray-300 text-tb-blue shadow-sm focus:border-tb-blue focus:ring focus:ring-tb-blue focus:ring-opacity-50"
                      />
                      <span class="ml-2 text-sm text-gray-700">All future carriers</span>
                    </label>
                    <span v-if="form.errors.all_carriers" class="text-red-500 text-xs">{{ form.errors.all_carriers }}</span>
                    <span v-if="form.errors.future_carriers" class="text-red-500 text-xs">{{ form.errors.future_carriers }}</span>
                  </div>

                  <div v-if="!form.all_carriers">
                    <!--                <label class="block text-sm font-medium text-gray-700">Select Carriers</label>-->
                    <!--                <select-->
                    <!--                  v-model="form.agencyCarriers"-->
                    <!--                  multiple-->
                    <!--                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-tb-blue focus:ring-tb-blue sm:text-sm"-->
                    <!--                >-->
                    <!--                  <option v-for="agencyCarrier in agencyCarriers" :key="agencyCarrier.agency_carrier_id" :value="agencyCarrier.agency_carrier_id">-->
                    <!--                    {{ agencyCarrier.carrier_name }}-->
                    <!--                  </option>-->
                    <!--                </select>-->
                    <div class="max-h-[600px] overflow-auto">
                      <CarrierLogoSelector v-model="form.agencyCarriers" :carriers="agencyCarriers" :select-all-button="false"  />
                    </div>
                    <span v-if="form.errors.agencyCarriers" class="text-red-500 text-xs">{{ form.errors.agencyCarriers }}</span>
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="flex justify-end">
                  <button
                    type="button"
                    @click="submit"
                    :disabled="processing"
                    class="inline-flex items-center px-4 py-2 bg-tb-blue border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-700 active:bg-blue-900 focus:outline-none focus:border-blue-900 focus:ring focus:ring-blue-300 disabled:opacity-25 transition"
                    :class="{'opacity-30': processing}"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLoading"
         class="fixed inset-0 z-[99999!important;] bg-white/80 flex justify-center items-center transition-opacity duration-300 opacity-100">
      <LoadingSpinner color-class="text-tb-blue h-8 w-8"></LoadingSpinner>
    </div>

    <!-- Create a modal that can be shown with this.$modal.show('carrier-modal') -->
    <Modal :show="editing_carriers_visible" title="Edit carriers" @onClose="closeCarrierUpdateModal" max-width="max-w-[1200px]">
      <div class="max-h-[600px] overflow-auto">
        <CarrierLogoSelector v-model="formCarriersUpdate.selectedCarriers" :carriers="agencyCarriers"></CarrierLogoSelector>
      </div>
      <!-- Submit Button -->
      <div class="flex justify-end">
        <button
          type="button"
          @click="submitCarrierUpdate"
          :disabled="processing"
          class="inline-flex items-center px-4 py-2 bg-tb-blue border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-700 active:bg-blue-900 focus:outline-none focus:border-blue-900 focus:ring focus:ring-blue-300 disabled:opacity-25 transition"
          :class="{'opacity-30': processing}"
        >
          Submit
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ref } from 'vue'
import {useForm} from "@inertiajs/inertia-vue3";
import {ElDatePicker, ElPopconfirm, ElTooltip} from "element-plus";
import {QuestionMarkCircleIcon} from "@heroicons/vue/solid";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import CarrierLogoSelector from "@/Components/CarrierLogoSelector.vue";
import Button from "@/Components/Button.vue";
import Modal from "@/Components/Modal.vue";

export default {
  name: 'AgentSaleSharing',
  
  components: {
      Modal,
      ElPopconfirm, Button,
      CarrierLogoSelector,
      LoadingSpinner,
      QuestionMarkCircleIcon,
      ElTooltip,
      ElDatePicker,
  },

  props: {
    agentId: {
      type: [Number, String],
      required: true
    }
  },

  setup() {
    const loading = ref(false)
    const processing = ref(false)
    const agencies = ref([])
    const agencyAgents = ref([])
    const agencyCarriers = ref([])
    const sharing = ref([])
    const editing_carriers_visible = ref(false)

    const form = useForm({
      source_user_id: '', // filled on submit
      agency_id: '',
      start_date: '',
      agencyCarriers: [],
      all_carriers: false,
      future_carriers: false,
      selectedCarriers: [],
    })
    const formCarriersUpdate = useForm({
        sale_sharings_id: null,
        selectedCarriers: [],
    })

    const disablePastDates = (time) => {
      return time.getTime() < Date.now() - 8.64e7 // Disable dates before today (86400000 ms = 1 day)
    }

    return {
      loading,
      processing,
      agencies,
      agencyAgents,
      agencyCarriers,
      form,
      formCarriersUpdate,
      disablePastDates,
      sharing,
      editing_carriers_visible,
    }
  },
  mounted: function() {
      this.setUpComponent();
  },
  methods: {
    async setUpComponent() {
        try {
            this.loading = true;
            const response = await axios.get(route('sale-sharing.mount'), {
                params: {
                    source_user_id: this.agentId,
                }
            });
            await this.redrawComponent(response)
        } catch (error) {
            console.error('Error setting up component:', error)
        }
        this.loading = false;
    },
    async redrawComponent(response) {
        const data = response.data
        this.agencies = data.agencies
        this.agencyCarriers = data.agencyCarriers
        this.sharing = data.sharing
    },
    async agencyChanged() {
        //await this.loadAgencyAgents();
    },
    async loadAgencyAgents() {
      if (!this.form.agency_id) {
        this.agencyAgents = []
        return
      }

      try {
        this.loading = true;
        const response = await axios.get(route('sale-sharing.get-users'), {
          params: {
            agency_id: this.form.agency_id,
            source_user_id: this.agentId,
          }
        });
        this.agencyAgents = response.data
      } catch (error) {
        console.error('Error loading agency agents:', error)
      }
      this.loading = false;
    },

    submit() {
      this.processing = true
      this.form.source_user_id = this.agentId;
      this.form.post(route('sale-sharing.store'), {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({message: 'Sales sharing configured successfully. If sharing past sales, those will be reflected in a few minutes.'})
          this.form.reset()
          this.setUpComponent()
        },
        onError: (error) => {
          error.message && $notify.error({message: error.message});
        },
        onFinish: () => {
          this.processing = false
        }
      })
    },

    submitCarrierUpdate() {
        this.processing = true
        this.form.source_user_id = this.agentId;
        this.formCarriersUpdate.put(route('sale-sharing.update.carriers', {id: this.formCarriersUpdate.sale_sharings_id}), {
            preserveScroll: true,
            onSuccess: () => {
                $notify.success({message: 'Sales sharing updated.'})
                this.form.reset()
                this.closeCarrierUpdateModal()
            },
            onError: (error) => {
                error.message && $notify.error({message: error.message});
            },
            onFinish: () => {
                this.processing = false
            }
        })
    },

    async toggleActive(share) {
        this.loading = true;
        axios.post(route('sale-sharing.toggle-active', {id: share.id}))
            .then(async (response) => {
                await this.redrawComponent(response)
            })
            .catch(error => {
                console.error('Error toggling active status:', error)
            })
            .finally(() => {
                this.loading = false
            })
    },
    async doDelete(share) {
        this.loading = true;
        axios.delete(route('sale-sharing.destroy', {id: share.id}))
            .then(async (response) => {
                await this.redrawComponent(response)
            })
            .catch(error => {
                console.error('Error deleting:', error)
            })
            .finally(() => {
                this.loading = false
            })
    },
    async showCarriers(share) {
        this.loading = true;
        axios.get(route('sale-sharing.carriers', {id: share.id}))
            .then(response => {
                this.formCarriersUpdate.sale_sharings_id = share.id
                this.formCarriersUpdate.selectedCarriers = response.data.map(i => i.agency_carrier_id)
                this.editing_carriers_visible = true
            })
            .catch(error => {
                console.error('Error getting carriers:', error)
            })
            .finally(() => {
                this.loading = false
            })
    },
    closeCarrierUpdateModal() {
        this.editing_carriers_visible = false
        this.formCarriersUpdate.reset()
    },
  },
  computed: {
      isLoading() {
          return this.loading || this.processing
      },
      isCreating() {
          return true
      },
      isEditing() {
          return ! this.isCreating
      },
      canSeeForm() {
          return this.isEditing || this.sharing.length === 0
      },
  },
  watch: {
    'form.all_carriers'(newValue) {
      if (newValue) {
        this.form.carriers = []
      }
    }
  }
}
</script> 